<template>
	<Tabs :tabs="tabs">
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
	</Tabs>
</template>

<script>
import { SET_COUNTRIES } from '@/store/mutations.type';

export default {
	name: 'AccountPage',
	data() {
		return {
			tabs: [
				{
					route: 'profile',
					name: 'Manage your profile'
				},
				{
					route: 'organisation',
					name: 'Your organisation'
				},
				{
					route: 'visuals',
					name: 'Your saved visuals and views'
				}
			]
		};
	},
	destroyed() {
		this.$store.commit(SET_COUNTRIES, []);
	}
};
</script>
